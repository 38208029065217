import React from 'react';
import styles from './main-landing.module.scss';
import { useTranslation } from 'next-i18next';
import { environment } from '@environment';
import { isMobile, getOS } from '@helpers/helper';
import { deeplinkIntoAppUsingBranch } from '@helpers/branch-helper';
import MobileCarousel from '@components/carousel/mobile-carousel';
import { useRouter } from 'next/router';
import reportAmplitudeEvent from '@helpers/analytics';
import { AmplitudeEvents } from '@enums/amplitude';

export const MainLandingComponent = () => {
	const { t, i18n } = useTranslation();
	const os = getOS();
	const router = useRouter();
	const lang =
		i18n.language || String(router.query?.lang) || String(router.query?.forcelang) || 'en';

	const images = [
		{
			id: 1,
			src: `${environment.web_cdn_url}web/assets/img/landing/main/carousel-images/explore-${os}-${lang}-updated.png`
		},
		{
			id: 2,
			src: `${environment.web_cdn_url}web/assets/img/landing/main/carousel-images/player-${os}-${lang}-updated.png`
		},
		{
			id: 3,
			src: `${environment.web_cdn_url}web/assets/img/landing/main/carousel-images/library-${os}-${lang}-updated.png`
		},
		{
			id: 4,
			src: `${environment.web_cdn_url}web/assets/img/landing/main/carousel-images/pdj-${os}-${lang}-updated.png`
		}
	];

	let renderedComponent = null;

	if (isMobile()) {
		renderedComponent = (
			<MobileCarousel
				images={images}
				cta={{
					text: 'mobile_landing_page_main_cta',
					img: `${environment.web_cdn_url}web/assets/img/landing/main/play-icon-cta.png`,
					styles: null
				}}
				title={{
					text: `<span class="textshadow">${t('mobile_landing_page_title_1')}</span><br/>${t(
						'mobile_landing_page_title_2'
					)}`,
					isHtml: true
				}}
				onCTAClick={() => {
					redirectApp();
				}}
			/>
		);
	}

	const redirectApp = () => {
		reportAmplitudeEvent(AmplitudeEvents.clickOnLandingCTA, {
			operatingSystem: os
		});
		deeplinkIntoAppUsingBranch('home');
	};

	setTimeout(() => {
		reportAmplitudeEvent(AmplitudeEvents.mainLanding, {
			device: 'mobile' // TODO: change it later on but because currently we only have it on mobile
		});
	}, 1000);

	return <div className={styles['landing-main-wrapper']}>{renderedComponent}</div>;
};

export default MainLandingComponent;
