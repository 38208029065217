import React from 'react';
import styles from './carousel.module.scss';
import { useTranslation } from 'next-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { IField, IButton, IImage } from '@interfaces/sections.interface';

interface MobileCarouselProps {
	mainCarouselClass?: string;
	isCarouselOnTop?: boolean;
	backgroundColor?: string;
	imageClass?: string;
	images: IImage[];
	title?: IField;
	subtitle?: IField;
	cta?: IButton;
	isDarkMode?: boolean;
	onCTAClick?: () => void; // emit action on button click
}

export const MobileCarousel = ({
	mainCarouselClass,
	isCarouselOnTop = true,
	backgroundColor,
	imageClass,
	images,
	title,
	subtitle,
	cta,
	isDarkMode,
	onCTAClick
}: MobileCarouselProps) => {
	const { t } = useTranslation();

	return (
		<div
			className={`${styles.MobileCarousel} ${styles['carousel-main-wrapper']} flexbox colls ${
				isDarkMode ? styles.dark_mode : ''
			} ${mainCarouselClass} ${styles[mainCarouselClass]}`}
			style={{
				flexDirection: isCarouselOnTop ? 'column' : 'column-reverse',
				alignItems: isCarouselOnTop ? 'inherit' : 'center'
			}}
		>
			<ScrollContainer vertical={false} className={styles.scroll_container}>
				{images.map((elt: any) => {
					return (
						<div key={`id-${elt.id}`} className={`flexbox colls start ${styles.benefit_box_wrap}`}>
							<img
								src={elt.src}
								className={`${styles['carousel-img']} ${styles[imageClass]}`}
								style={{
									backgroundColor
								}}
							/>
							<div className={styles.title}>{t(elt?.title)}</div>
							<div className={styles.subtitle}>{t(elt?.subtitle)}</div>
						</div>
					);
				})}
			</ScrollContainer>
			{title || cta ? (
				<div className={`flexbox colls ${styles.details_wrapper}`}>
					{title && (
						<div className={styles['carousel-title']} style={title?.styles}>
							{title?.isHtml ? (
								<span dangerouslySetInnerHTML={{ __html: title?.text }} />
							) : (
								t(title?.text)
							)}
						</div>
					)}
					{subtitle && (
						<div className={styles['carousel-subtitle']} style={subtitle?.styles}>
							{t(subtitle?.text)}
						</div>
					)}
					{cta && (
						<button
							type="button"
							className={`flexbox default-btn landing ${styles['cta-btn']}`}
							style={cta.styles}
							onClick={() => {
								onCTAClick();
							}}
						>
							{cta?.img && (
								<img
									src={cta?.img}
									style={{
										maxWidth: cta?.text ? '1.5em' : '10em'
									}}
								/>
							)}
							<span className={styles['cta-padding']}>{t(cta?.text)}</span>
						</button>
					)}
				</div>
			) : null}
		</div>
	);
};

export default MobileCarousel;
