import React from 'react';
import { PageWrapper } from '@components/pagewrapper/pagewrapper';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { MainLandingComponent } from '@components/main-landing';
import { initNeoGatewayServer } from '@helpers/neogateway';
import withAuth from '@components/auth';

export const MainLandingPage = () => {
	return (
		<PageWrapper
			headerProps={{
				styles: {
					backgroundColor: '#FFAE83',
					boxShadow: 'none',
					paddingTop: '1em'
				},
				logo: 'white'
			}}
			mainLayoutStyle={{
				backgroundColor: '#FFAE83'
			}}
		>
			<MainLandingComponent/>
		</PageWrapper>
	);
};

export async function getServerSideProps(ctx) {
	ctx.res.setHeader(
		'Cache-Control',
		'public, s-maxage=14400, stale-while-revalidate=21600'
	);

	const { pageProps } = await initNeoGatewayServer(ctx);
	return {
		props: {
			...pageProps,
			...(await serverSideTranslations(ctx.locale, ['common']))
		}
	};
}

export default withAuth(MainLandingPage);
